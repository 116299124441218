.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem;
}
.header-menu{
    display: flex;
    align-items: center;
    gap: 2rem;
    list-style: none;
    color: white;
    font-weight: 16px;
    font-weight: 600;
}
.header-menu li:hover {
    cursor: pointer;
   color: var(--orange);
   width: 5.4rem;
}

@media screen and (max-width: 768px){
    .header>:nth-child(2)
    {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu
    {
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;

    }
}
